<template>
  <el-dialog
    append-to-body
    width="600px"
    :title="`${form.data.status === 1 ? '封号' : '恢复'}`"
    :visible.sync="visible"
    :before-close="reset"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
  >
    <el-form
      v-loading="loading"
      element-loading-text="加载中…"
      ref='elFormDom'
      label-width='110px'
      label-position='right'
      :model='form.data'
      :rules='form.rules'
    >
    <!-- 封号 -->
    <template v-if="form.data.status === 1">
      <el-form-item prop='reason' label='处罚原因'>
        <el-select v-model="form.data.reason" placeholder="请选择处罚原因">
          <el-option
            v-for="item in options"
            :key="item.id"
            :label="item.name"
            :value="item.name">
          </el-option>
        </el-select>
      </el-form-item>
      <!-- 师傅端用户 -->
      <template v-if="this.userType === 2">
        <el-form-item prop='date' label='封号期限'>
          <el-date-picker
            v-if="form.data.is_forever === false"
            v-model="form.data.date"
            type="date"
            placeholder="选择日期"
            format="yyyy 年 MM 月 dd 日"
            value-format="yyyy-MM-dd"
            @change="handleTime">
          </el-date-picker>
          <el-checkbox style="margin-left:10px;" v-model="form.data.is_forever" border @change="handleYj">永久</el-checkbox>
        </el-form-item>
        <!-- <el-form-item prop="tz" label="发送通知">
          <el-input
            placeholder="请输入内容"
            v-model="form.data.tz"
            clearable
            :readonly="true"
            type="textarea"
            :autosize="{ minRows: 2, maxRows: 6}">
          </el-input>
        </el-form-item> -->
      </template>

      <!-- 需求端用户 -->
      <template v-if="this.userType === 1">
        <el-form-item prop='order_id' label='关联工单'>
          <el-select v-model="form.data.order_id" placeholder="请选择关联工单">
            <el-option
              v-for="item in orderList"
              :key="item.id"
              :label="item.title"
              :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item prop='date' label='处罚期限'>
          <el-date-picker
            v-if="form.data.is_forever === false"
            v-model="form.data.date"
            type="date"
            placeholder="选择日期"
            format="yyyy 年 MM 月 dd 日"
            value-format="yyyy-MM-dd"
            @change="handleTime">
          </el-date-picker>
          <el-checkbox style="margin-left:10px;" v-model="form.data.is_forever" border @change="handleYj">永久</el-checkbox>
        </el-form-item>
      </template>
      <el-form-item prop="tz" label="发送通知">
        <el-input
          placeholder="请输入内容"
          v-model="form.data.tz"
          clearable
          :readonly="true"
          type="textarea"
          :autosize="{ minRows: 2, maxRows: 6}">
        </el-input>
      </el-form-item>
      <el-form-item prop="account" label="发送账号">
        <el-input
          v-model="form.data.account"
          :readonly="true">
        </el-input>
      </el-form-item>
    </template>
    <!-- 恢复 -->
    <template v-else>
      <el-form-item prop='date' label='恢复时间'>
        <el-date-picker
          v-model="form.data.date"
          type="date"
          placeholder="选择日期"
          format="yyyy 年 MM 月 dd 日"
          value-format="yyyy-MM-dd">
        </el-date-picker>
      </el-form-item>
    </template>
    </el-form>
    <template #footer>
      <el-row type="flex" justify="end">
        <el-button @click='toggle(false)'>取消</el-button>
        <el-button type="primary" :loading="form.loading" :disabled="form.loading" @click='confirm'>确定</el-button>
      </el-row>
    </template>
  </el-dialog>
</template>

<script>
import common from '@/util'
  export default {
    name: 'UserEdit',
    data() {
      return {
        visible: false,
        isChange: false,
        loading: false,
        form: {
          loading: false,
          data: {
            status: '',
            is_forever: false,
            date: '',
            account: '',
            reason: '',
            order_id: '',
            tz: '',
          },
          rules: {
            reason: [{ required: true, message: '请选择处罚原因', trigger: 'change' }],
            date: [{ required: true, message: '请选择期限', trigger: 'change' }],
          }
        },
        options: [
          {
            id:1,
            name: '信息虚假'
          },{
            id:2,
            name: '涉嫌欺诈'
          },{
            id:3,
            name: '被多人举报'
          }
        ],
        userType: '', // 类型 1:雇主 2:工人
        orderList: [],
      }
    },
    created() {
      this.getOrderList();
    },
    mounted() {
      // this.form.data.tz = `尊敬的用户您好，经系统审查您因严重违反平台服务规则，被多次举报发布虚假信息、不让线上交易、涉嫌欺诈、发布违规内容等，您的账号已被封禁，封禁时间至${this.form.data.date}。为创建良好诚信网络交易平台，请及时整改，如再次出现将会永久封号，敬请谅解，感谢您的配合。`
    },
    methods: {
      // 获取工单列表
      getOrderList() {
        this.$http.get('/admin/flex_order/list', {params:{page:1,count:10000,argument:1}}).then(res => {
          if(res.code === 1) {
            let str = {
              id: '',
              title: '无工单'
            }
            this.orderList = res.data.list;
            this.orderList.unshift(str)
          } else {
            this.$message.error(res.msg)
          }
        })
      },
      // 获取详情
      getDetail(row, userType) {
        this.form.data.is_forever = false;
        this.isChange = true
        this.userType = userType;
        this.curId = row.id;
        this.$http.get('/admin/flex_user/info', {params:{id: row.id} }).then(res => {
          if(res.code === 1) {
            common.deepClone(this.form.data, res.data.register)
          } else {
            this.$message.error(res.msg)
          }
        })
      },
      reset(done) {
        this.isChange = false
        this.$refs.elFormDom.resetFields()
        done && done()
      },
      toggle(show) {
        this.visible = show
        !show && this.reset()
      },
      // 提交
      confirm() {
        this.$refs.elFormDom.validate(valid => {
          if (valid) {
            this.form.loading = true;
            // status 1:正常（封号） 2:异常（解封）
            let _param = {}
            if(this.form.data.status === 1) {
              _param = {
                id: this.curId,
                reason: this.form.data.reason,
                order_id: this.form.data.order_id,
                is_forever: this.form.data.is_forever === true ? 1 : 2,
                date: this.form.data.date,
              }
            } else if(this.form.data.status === 2) {
              _param = {
                id: this.curId,
                date: this.form.data.date
              }
            }
            
           let apiUrl = this.form.data.status === 1 ? '/admin/flex_user/seal' : '/admin/flex_user/unlock'
            this.$http.post(apiUrl, _param).then(res => {
              if(res.code === 1) {
                this.toggle(false);
                this.$emit('refresh')
              } else {
                this.$message.error(res.msg)
              }
            }).finally(() => {
              this.form.loading = false;
            })
          }
        })
      },
      beforeAvatarUpload(file) {
        const isLt5M = file.size / 1024 / 1024 < 5;
        if (!isLt5M) {
          this.$message.error('上传文件大小不能超过 5MB!');
        }
        return isLt5M;
      },
      httpRequest(file) {
        let formData = new FormData();
        formData.append('file', file.file);
        this.$http.post('common/qiniu/uploadFile', {formData, type:'upload'}).then(res => {
          this.form.data.image = res.data.all_path_url;
        })
      },
      handleYj(val) {
        if(val === true) {
          this.form.data.date = ''
          this.form.rules.date = [{}]
          this.form.data.tz = '尊敬的用户您好，经系统审查您因严重违反平台服务规则，存在被多次举报、发布虚假信息、不让线上交易、涉嫌欺诈、发布违规内容等相关平台禁止问题，您的账号已被封禁，封禁时间永久。为创建良好诚信网络交易平台，需诚信使用账号，如有不便敬请谅解。'
        } else {
          this.form.rules.date = [{ required: true, message:'请选择封号期限', trigger: 'change' }]
        }
      },
      handleTime(value) {
        this.form.data.tz = `尊敬的用户您好，经系统审查您因严重违反平台服务规则，被多次举报发布虚假信息、不让线上交易、涉嫌欺诈、发布违规内容等，您的账号已被封禁，封禁时间至${value}。为创建良好诚信网络交易平台，请及时整改，如再次出现将会永久封号，敬请谅解，感谢您的配合。`
      }

    }
  }
</script>
