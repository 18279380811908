<template>
  <div div class="addPerson__wrap" v-show="visible">
    <el-button style="margin:20px;" plain icon="el-icon-d-arrow-left" @click="toggle(false)">返回</el-button>
    <div class="main">
      <el-radio-group v-model="status" @change="handleRadio" style="margin-bottom:20px;">
        <el-radio-button :label="1">注册信息</el-radio-button>
        <el-radio-button :label="2">实名认证</el-radio-button>
        <el-radio-button :label="3" v-if="this.userType === 2">名片信息</el-radio-button>
        <el-radio-button :label="4" v-if="this.userType === 2">完工照片</el-radio-button>
        <el-radio-button :label="5">处罚记录</el-radio-button>
      </el-radio-group>
      <template v-if="this.page === 1">
        <el-divider content-position="left">注册信息</el-divider>
        <div class="info-top">
          <div style="text-align:left;">
            <p>名称：{{dataInfo.register.name}}</p>
            <p>注册账号：{{dataInfo.register.account}}</p>
            <p>认证时间：{{dataInfo.register.create_time}}</p>
          </div>
          <el-avatar
            style="width: 80px; height: 80px;margin-left:50px"
            shape="square"
            :src="dataInfo.register.avatar"
            fit="cover">
            <img src="https://cube.elemecdn.com/3/7c/3ea6beec64369c2642b92c6726f1epng.png"/>
          </el-avatar>
        </div>
      </template>
      <!-- 实名认证 -->
      <template v-if="this.page === 2">
        <el-descriptions title="" :column="1" border v-if="!!dataInfo.certification.id_card">
          <el-descriptions-item label="认证时间">{{dataInfo.certification.create_time}}</el-descriptions-item>
          <el-descriptions-item label="姓名">{{dataInfo.certification.name}}</el-descriptions-item>
          <el-descriptions-item label="性别">{{dataInfo.certification.sex === 1 ? '男' : '女'}}</el-descriptions-item>
          <el-descriptions-item label="身份证号">{{dataInfo.certification.id_card}}</el-descriptions-item>
          <el-descriptions-item label="出生日期">{{dataInfo.certification.birthday}}</el-descriptions-item>
          <el-descriptions-item label="户籍地址">{{dataInfo.certification.native}}</el-descriptions-item>
          <el-descriptions-item label="身份证照片">
            <el-image
              v-if="dataInfo.certification.card_url"
              style="width: 200px; height: 150px"
              :src="dataInfo.certification.card_url"
              fit="cover"
              :preview-src-list="[dataInfo.certification.card_url]"></el-image>
          </el-descriptions-item>
        </el-descriptions>
        <p v-else>无认证信息</p>
      </template>
      <!-- 名片信息 只有师傅端显示 -->
      <template v-if="this.page === 3">
        <el-descriptions title="" :column="1" border v-if="dataInfo.certification.name">
          <el-descriptions-item label="工龄" :labelStyle='labelStyle'>{{dataInfo.certification.work_age}}</el-descriptions-item>
          <el-descriptions-item label="身份" :labelStyle='labelStyle'>{{toStr({0:'未填',1:'学生',2:'自由职业',3:'宝妈',4:'全职'},dataInfo.certification.type)}}</el-descriptions-item>
          <el-descriptions-item label="擅长工种" :labelStyle='labelStyle'>{{dataInfo.certification.kinds}}</el-descriptions-item>
          <el-descriptions-item label="联系电话" :labelStyle='labelStyle'>{{dataInfo.certification.phone}}</el-descriptions-item>
          <el-descriptions-item label="所在址" :labelStyle='labelStyle'>{{dataInfo.certification.address}}</el-descriptions-item>
        </el-descriptions>
        <p v-else>无名片信息</p>
      </template>
      <!-- 完工照片 只有师傅端显示 -->
      <template v-if="this.page === 4">
        <el-descriptions direction="vertical" title="" :column="1" :colon="false" v-if="dataInfo.order.length > 0">
          <el-descriptions-item :label="item.complete_time" v-for="item in dataInfo.order" :key="item">
            <span v-for="img in item.complete_image" :key="img" style="margin-right:10px;">
              <el-image
                style="width: 80px; height: 80px"
                :src="img"
                fit="cover"
                :preview-src-list="srcList"></el-image>
            </span>
          </el-descriptions-item>
        </el-descriptions>
        <p v-else>无完工照片</p>
      </template>
      <!-- 处罚记录 -->
      <template v-if="this.page === 5">
        <p>账号状态： {{dataInfo.register.status === 1 ? '正常' : '异常'}}</p>
        <p v-if="dataInfo.register.status === 1">处罚记录：无</p>
        <el-table
          v-else
          :data="dataInfo.punish"
          border
          style="width: 100%"
          :header-cell-style="{background:'#FAFAFA'}"
          >
          <el-table-column
            type="index"
            label="序号"
            width="50">
          </el-table-column>
          <el-table-column
            prop="create_time"
            label="处罚时间"
            width="180">
          </el-table-column>
          <el-table-column
            prop="reason"
            label="处罚原因"
            width="180">
          </el-table-column>
          <el-table-column
            prop="job_title"
            label="关联工单">
          </el-table-column>
          <el-table-column
            prop="start_time"
            label="封号期限">
            <template v-slot="scope">
              <span v-if="scope.row.disable_time !== 1">{{scope.row.start_time}}至{{scope.row.disable_time}}</span>
              <span v-else>永久封号</span>
            </template>
          </el-table-column>
        </el-table>
      </template>
    </div>
  </div>
</template>

<script>
export default {
  name: 'UserDetail',
  data() {
    return {
      visible: false,
      dataInfo: {
        register: {},
        certification: {},
        punish: [],
        order: {}
      },
      page: 1,
      status: 1,
      userType: '',
      labelStyle: { 'width': '160px' },
    }
  },
  methods: {
    // 获取详情
    getDetail(row, userType) {
      this.status = 1;
      this.page = 1;
      this.isChange = true
      this.userType = Number(userType)
      console.log(this.userType)
      this.$http.get('/admin/flex_user/info', {params:{id: row.id} }).then(res => {
        if(res.code === 1) {
         this.dataInfo = res.data
          this.dataInfo.happen_at = this.$moment(res.data.created_at).format('YYYY-MM-DD')
        } else {
          this.$message.error(res.msg)
        }
      })
    },
    reset(done) {
      this.isChange = false
      // this.$refs.elFormDom.resetFields()
      done && done()
    },
    toggle(show) {
      this.visible = show
      !show && this.reset()
    },
    handleRadio(value) {
      this.page = value
    },
    toStr(data, key) {
      return data[key]
    }
  }
}
</script>
<style scoped lang="scss">
.addPerson__wrap {
  @include position($t: 0, $r: 0, $b: 0, $l: 0);
  background-color: #fff;
  margin: 10px;
  z-index: 5 !important;
  overflow: scroll;
  .main {
    width: 80%;
    margin: 0 auto;

    .info-top{
      display: flex;
      justify-content: start;
      align-items: center;
    }

    .title {
      font-size: 28px;
      font-weight: bold;
    }
    .time {
      font-size: 18px;
      color: #8C8C8C;
      margin-bottom: 20px;
    }
  }
  
}
.portrait {
  text-align: center;
}
.portrait ::v-deep .el-avatar--large {
  width: 60px;
  height: 60px;
  line-height: 40px;
}
</style>